import React from 'react'
import { Box, Grid } from '@mui/material'

export default function index() {
  const views = [
    {
      id: 0,
      title: 'Custom Graphics',
      description:
        'Ensures your website looks and functions perfectly on all devices, adapting the layout for optimal viewing.',
    },
    {
      id: 1,
      title: 'Print-Ready Files',
      description:
        'Tailored content management systems giving you full control over your website content with ease.',
    },
    {
      id: 2,
      title: 'Branding Consistency',
      description:
        'Comprehensive e-commerce solutions providing a seamless shopping experience and robust management tools.',
    },
    {
      id: 3,
      title: 'High-Resolution Designs',
      description:
        'Built with SEO best practices to improve visibility on search engines and drive organic traffic.',
    },
    {
      id: 4,
      title: 'Social Media Integration',
      description:
        'Optimizes website speed and performance for fast load times and smooth navigation.',
    },
  ]
  return (
    <Box
      sx={{
        marginTop: '5rem',
        marginLeft: { xs: '0.3rem', md: '3rem' },
        marginRight: { xs: '0.3rem', md: '0' },
      }}
      className="banner-design"
    >
      <img
        src="/assets/Banner/banner.svg"
        style={{ maxWidth: '75px' }}
        alt=""
      />
      <h2>Banner & Poster Design</h2>
      <Grid container>
        <Grid item xs={12} md={7}>
          <p
            style={{ margin: '1rem 0' }}
            className="grey-text-100 font-size-15"
          >
            Our expert team crafts visually stunning and user-friendly websites
            that not only capture your brand’s essence but also provide a
            seamless user experience. From concept to launch, we ensure your
            website stands out and drives results.
          </p>
          <h3>Key Features:</h3>
          <Grid container>
            <Grid item xs={11} md={6}>
              {views.splice(0, 3).map((value) => {
                return (
                  <Box
                    className="nard-list-item"
                    sx={{
                      margin: '1rem 0',
                      marginRight: '0.8rem',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                  >
                    <img src="/assets/Web/bluebox.svg" alt="" />
                    <Box sx={{ marginLeft: '1rem' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
            <Grid item xs={11} md={6}>
              {views.map((value) => {
                return (
                  <Box
                    className="nard-list-item"
                    sx={{
                      margin: '1rem 0',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                  >
                    <img src="/assets/Web/bluebox.svg" alt="" />
                    <Box sx={{ marginLeft: '1rem' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src="/assets/Banner/side-image.png"
              style={{ maxWidth: 'auto', maxHeight: '500px' }}
              alt=""
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
