import React, { useState } from 'react'
import {
  Box,
  Grid,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

export default function About() {
  const [selectedTestimonial, setSelectedTestimonial] = useState(1)

  const testimonials = [
    {
      id: 1,
      title: `“I am happy with NardFxPro performance for the previous year.
      The investment returns are unexpectedly tremendous. Smart
      investors will definitely agree with my statement. Highly
      recommended...”`,
      name: 'Eddie Choong',
      location: 'New York, USA',
    },
    {
      id: 2,
      title: `"I have been using NardFxPro for some time. Started with curious heart and its has been absolutely amazing since then. Automated trading really help make the process easier and efficient. Would recommend it to everyone to try."`,
      name: 'Marcus Ng',
      location: 'Tokyo, Japan',
    },
  ]

  const handleTestimonialChange = (direction) => {
    if (direction === 'prev' && selectedTestimonial > 1) {
      setSelectedTestimonial(selectedTestimonial - 1)
    } else if (
      direction === 'next' &&
      selectedTestimonial < testimonials.length
    ) {
      setSelectedTestimonial(selectedTestimonial + 1)
    }
  }

  const handleClick = (id) => {
    if (id !== selectedTestimonial) {
      const previousSelected = document.getElementById(
        `testimonial-${selectedTestimonial}`,
      )
      const clickedCard = document.getElementById(`testimonial-${id}`)

      // Remove any existing animation classes
      previousSelected.classList.remove('animate-forward', 'animate-backward')
      clickedCard.classList.remove('animate-forward', 'animate-backward')

      // Determine direction and apply the correct animation
      if (id > selectedTestimonial) {
        clickedCard.classList.add('animate-forward')
      } else {
        clickedCard.classList.add('animate-backward')
      }

      setSelectedTestimonial(id)
    }
  }

  return (
    <Box className="about" id="testimonials">
      <Box sx={{ marginLeft: { xs: '2.5rem', md: '7rem' }, marginTop: '6rem' }}>
        <Grid container>
          <Grid item xs={12} md={6}>
            <span>Testimonials</span>
            <div>
              <h2 className="font-size-45">
                What People Say <br /> About Us
              </h2>
              <img
                className="decore"
                src="/assets/About/Decore.png"
                style={{ paddingBottom: '2rem' }}
                alt=""
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            md={5}
            className="main-container"
            sx={{ marginBottom: '6rem', position: 'relative' }}
          >
            {testimonials.map((testimonial) => (
              <Box
                key={testimonial.id}
                id={`testimonial-${testimonial.id}`}
                className={
                  selectedTestimonial === testimonial.id
                    ? 'container-review'
                    : 'container-review-light'
                }
                onClick={() => handleClick(testimonial.id)}
              >
                {selectedTestimonial === testimonial.id && (
                  <div
                    style={{
                      backgroundColor: '#ff7c09',
                      borderRadius: '50%',
                      height: '50px',
                      width: '50px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: '-25px',
                      left: '-10px',
                    }}
                  >
                    <p style={{ color: 'white', fontWeight: 'bolder' }}>
                      {testimonial.name.split(' ')[0][0]}
                      {testimonial.name.split(' ')[1][0]}
                    </p>
                  </div>
                )}
                <p style={{ margin: '1rem 0' }} className="grey-text-100">
                  {testimonial.title}
                </p>
                <h5 className="grey-text-100" style={{ marginTop: '1rem' }}>
                  {testimonial.name}
                </h5>
                <h6 className="grey-text-100">{testimonial.location}</h6>
              </Box>
            ))}
          </Grid>

          <Grid item xs={1}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={selectedTestimonial === 1}
                onClick={() => handleTestimonialChange('prev')}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
              <IconButton
                sx={{ marginTop: '0.7rem' }}
                onClick={() => handleTestimonialChange('next')}
                disabled={selectedTestimonial === testimonials.length}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="container">
        <Box className="subscribe">
          <img src="/assets/About/send.svg" alt="" className="send" />
          <img
            src="/assets/About/mask-right.png"
            alt=""
            className="mask-right"
          />
          <img src="/assets/About/mask-left.png" alt="" className="mask-left" />
          <Box className="heading">
            <h3>
              Subscribe to get information, latest news and other interesting
              offers about The Nard & Co. Holdings Pte. Ltd.
            </h3>
          </Box>
          <Box className="field">
            <TextField
              id="outlined-basic"
              placeholder="Your email"
              variant="outlined"
              sx={{
                '& .MuiOutlinedInput-root': {
                  fontSize: '14px',
                  fontWeight: 600,
                  width: '400px',
                  borderRadius: '10px',
                  backgroundColor: '#ffffff',
                  '& fieldset': {
                    border: 'none',
                  },
                  '&:hover fieldset': {
                    border: 'none',
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img
                      src="/assets/About/mail.png"
                      alt=""
                      style={{ margin: '0 0.5rem 0 1rem' }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              style={{
                backgroundColor: '#29659A',
                marginLeft: '1rem',
                padding: '0.8rem 2.5rem',
                borderRadius: '10px',
              }}
            >
              <small
                className="text-transform font-size-15 font-weight-600"
                style={{ color: '#FAFAFA' }}
              >
                Subscribe
              </small>
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
