import React from 'react'
import { Box, Grid } from '@mui/material'
import { useState } from 'react'

export default function NardFx() {
  const views = [
    {
      id: 0,
      title: 'Automated Trading for Efficiency',
      description:
        'Experience enhanced efficiency through automated trading, offering advantages over manual forex trading.',
      icon: '/assets/NardFx/1.svg',
      mainImage: '/assets/NardFx/first.png',
    },
    {
      id: 1,
      title: 'Invitation Code Access',
      description:
        'Ensure exclusivity by requiring users to input invitation codes for access, fostering a sense of membership.',
      icon: '/assets/NardFx/2.svg',
      mainImage: '/assets/NardFx/second.png',
    },
    {
      id: 2,
      title: 'User-Friendly Procedure',
      description: `Seamlessly register and access introductory content about the bot's advantages, rental process, and performance.`,
      icon: '/assets/NardFx/3.svg',
      mainImage: '/assets/NardFx/third.png',
    },
    {
      id: 3,
      title: 'Dashboard for Referral Tracking',
      description:
        'Empower affiliates with a dedicated dashboard to track referrals and monitor commission earnings effortlessly.',
      icon: '/assets/NardFx/4.svg',
      mainImage: '/assets/NardFx/fourth.png',
    },
    {
      id: 4,
      title: 'Integrated Ticketing System',
      description:
        'Engage users effectively with an integrated ticketing system, facilitating user feedback and support requests.',
      icon: '/assets/NardFx/5.svg',
      mainImage: '/assets/NardFx/five.png',
    },
  ]
  const [selected, setSelected] = useState(views[0])
  const [animate, setAnimate] = useState(true)
  const handleHover = (value) => {
    if (selected.id !== value.id) {
      setAnimate(false)
      setTimeout(() => {
        setSelected(value)
        setAnimate(true)
      }, 500) // Duration should match the CSS transition duration
    }
  }
  const handleMouseLeave = (value) => {
    setAnimate(false)
    setTimeout(() => {
      setSelected(views[0])
      setAnimate(true)
    }, 500) // Duration should match the CSS transition duration
  }
  return (
    <Box
      sx={{
        marginTop: '5rem',
        marginLeft: { xs: '0.7rem', md: '3rem' },
        marginRight: { xs: '0.3rem', md: '0' },
        marginBottom: '0.4rem',
      }}
      className="nard-fx"
      id="nardfxpro"
    >
      <div
        className="font-size-15 font-weight-500"
        style={{ color: '#5E6282' }}
      >
        Automated Trading Bot
      </div>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img
          src="/assets/NardFx/fx-logo.svg"
          alt=""
          style={{ maxWidth: '75px' }}
        />
        <h2 className="font-size-45" style={{ marginLeft: '1rem' }}>
          NardFxPro
        </h2>
      </Box>
      <h6 className="grey-text-100 font-size-15">
        Maximize Your Trading Potential
      </h6>
      <Grid container>
        <Grid item xs={11} md={7}>
          <p
            style={{ margin: '1rem 0' }}
            className="grey-text-100 font-size-15"
          >
            This is our privileged tech that only open to invited Highly - Net
            Worth individual. Upon physical meet-up and approved with the
            Founder of NardFxPro, you will get a private invitation code for
            account opening. This is where you will unleash the power of having
            a Virtual Employee (The AI Bot Tech) to handle all your Trading
            needs.
          </p>
          {/* <Box>
            <img src="/assets/NardBiz/GooglePlay.svg" alt="" />
            <img
              src="/assets/NardBiz/PlayStore.svg"
              alt=""
              style={{ marginLeft: "0.5rem" }}
            />
          </Box> */}
          <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
            <Grid item xs={12} md={6} xl={6} sx={{ fontSize: { lg: '17px' } }}>
              {views.slice(0, 3).map((value) => {
                return (
                  <Box
                    className={
                      value.id === selected.id
                        ? 'nard-list-item-hover'
                        : 'nard-list-item'
                    }
                    sx={{
                      margin: '1rem 0',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                    onMouseOver={() => handleHover(value)}
                    onMouseLeave={() => handleMouseLeave(value)}
                  >
                    <img src={value.icon} alt="" />
                    <Box sx={{ marginLeft: '1rem' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
            <Grid item xs={12} md={6} xl={6} sx={{ fontSize: { lg: '17px' } }}>
              {views.slice(3, 5).map((value) => {
                return (
                  <Box
                    className={
                      value.id === selected.id
                        ? 'nard-list-item-hover'
                        : 'nard-list-item'
                    }
                    sx={{
                      margin: '1rem 0',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                    onMouseOver={() => handleHover(value)}
                    onMouseLeave={() => handleMouseLeave(value)}
                  >
                    <img src={value.icon} alt="" />
                    <Box sx={{ marginLeft: '1rem' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {views.map((value) => {
            return (
              <Box
                key={value.id}
                sx={{ display: 'flex', justifyContent: 'center' }}
              >
                {value.id === selected.id && (
                  <img
                    src={value.mainImage}
                    alt=""
                    style={{ maxWidth: '100%', maxHeight: '470px' }}
                    className={animate ? 'image-animate' : 'image-none'}
                  />
                )}
              </Box>
            )
          })}
        </Grid>
      </Grid>
    </Box>
  )
}
