import { Box, Grid } from '@mui/material'
import React from 'react'

export default function index() {
  const views = [
    {
      id: 0,
      title: 'Cross-Platform Development',
      description:
        'Ensures your website looks and functions perfectly on all devices, adapting the layout for optimal viewing.',
    },
    {
      id: 1,
      title: 'API Integration',
      description:
        'Tailored content management systems giving you full control over your website content with ease.',
    },
    {
      id: 2,
      title: 'E-comPush Notificationsmerce Solutions',
      description:
        'Comprehensive e-commerce solutions providing a seamless shopping experience and robust management tools.',
    },
    {
      id: 3,
      title: 'User-Centric Design',
      description:
        'Built with SEO best practices to improve visibility on search engines and drive organic traffic.',
    },
    {
      id: 4,
      title: 'Real-Time Analytics',
      description:
        'Optimizes website speed and performance for fast load times and smooth navigation.',
    },
  ]

  const teckStack = [
    {
      id: 0,
      title: 'Frontend Languages',
      language: ['React Native', 'Flutter, Swift', 'Kotlin'],
    },
    {
      id: 1,
      title: 'Backend Languages',
      language: ['Node.js', 'Firebase', 'GraphQL'],
    },
    {
      id: 2,
      title: 'Database',
      language: ['SQLite', 'Realm', 'Firebase Fire store'],
    },
  ]
  return (
    <Box
      sx={{
        marginTop: '4rem',
        marginLeft: { xs: '0.3rem', md: '3rem' },
        marginRight: { xs: '0.3rem', md: '0' },
      }}
      className="mobile-nav"
    >
      <img
        src="/assets/Mobile/mobile.svg"
        style={{ maxWidth: '75px' }}
        alt=""
      />
      <h2>Mobile Design and Development</h2>
      <Grid container>
        <Grid item xs={12} md={7}>
          <p
            style={{ margin: '1rem 0' }}
            className="grey-text-100 font-size-15"
          >
            Our expert team crafts visually stunning and user-friendly websites
            that not only capture your brand’s essence but also provide a
            seamless user experience. From concept to launch, we ensure your
            website stands out and drives results.
          </p>
          <h3>Key Features:</h3>
          <Grid container>
            <Grid item xs={11} md={6}>
              {views.splice(0, 3).map((value) => {
                return (
                  <Box
                    className="nard-list-item"
                    sx={{
                      margin: '1rem 0',
                      marginRight: '0.8rem',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                  >
                    <img src="/assets/Web/bluebox.svg" alt="" />
                    <Box sx={{ marginLeft: '1rem' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
            <Grid item xs={11} md={6}>
              {views.map((value) => {
                return (
                  <Box
                    className="nard-list-item"
                    sx={{
                      margin: '1rem 0',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                  >
                    <img src="/assets/Web/bluebox.svg" alt="" />
                    <Box sx={{ marginLeft: '1rem' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box sx={{ paddingTop: '7rem' }}>
            <img
              src="/assets/Mobile/first.png"
              alt=""
              style={{ maxWidth: 'auto' }}
            />
          </Box>
          <Box sx={{ margin: '0', padding: '0' }}>
            <img
              src="/assets/Mobile/2.png"
              alt=""
              style={{ maxWidth: '168PX' }}
            />
          </Box>
        </Grid>
      </Grid>
      <h3 style={{ marginTop: '1rem' }}>Tech Stack:</h3>
      <Grid container sx={{ marginTop: '0.5rem' }}>
        {teckStack.map((value) => {
          return (
            <Grid
              item
              xs={11}
              md={3}
              className="nard-list-item"
              sx={{
                margin: '1rem 0',
                marginRight: '0.8rem',
                display: 'flex',
                alignItems: 'start',
                padding: '1rem',
              }}
              key={value.id}
            >
              <img src="/assets/Web/bluebox.svg" alt="" />
              <Box sx={{ marginLeft: '1rem' }}>
                <span className="font-weight-600">{value.title}</span>
                <ul style={{ margin: '0.5rem 0 0 1.4rem' }}>
                  {value.language.map((nestedValue) => {
                    return (
                      <li
                        className="grey-text-100 font-size-15"
                        key={nestedValue}
                      >
                        {nestedValue}
                      </li>
                    )
                  })}
                </ul>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    </Box>
  )
}
