import React from 'react'
import { Box, Grid, Button, Typography } from '@mui/material'
import { useState } from 'react'

export default function Services() {
  const views = [
    {
      id: 1,
      title: 'Website Design and Development',
      description:
        'Empower affiliates with a dedicated dashboard to track referrals and monitor commission earnings effortlessly',
      image: '/assets/Services/first.svg',
      mainImage: '/assets/Services/laptop.png',
    },
    {
      id: 2,
      title: 'Mobile Design and Development',
      description:
        'Empower affiliates with a dedicated dashboard to track referrals and monitor commission earnings effortlessly',
      image: '/assets/Services/second.png',
      mainImage: '/assets/Services/first.png',
    },
    {
      id: 3,
      title: 'Banner & Poster Design',
      description:
        'Empower affiliates with a dedicated dashboard to track referrals and monitor commission earnings effortlessly',
      image: '/assets/Services/third.svg',
      mainImage: '/assets/Services/card.png',
    },
    {
      id: 4,
      title: 'Name Card Design',
      description:
        'Empower affiliates with a dedicated dashboard to track referrals and monitor commission earnings effortlessly',
      image: '/assets/Services/four.svg',
      mainImage: '/assets/Services/name-card.png',
    },
  ]
  const [selected, setSelected] = useState(views[0])
  const [animate, setAnimate] = useState(true)
  const handleHover = (value) => {
    setAnimate(false)
    setTimeout(() => {
      setSelected(value)
      setAnimate(true)
    }, 500)
  }

  const handleMouseLeave = () => {
    setAnimate(false)
    setTimeout(() => {
      setSelected(views[0])
      setAnimate(true)
    }, 500)
  }
  return (
    <Box className="offered-services">
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '5rem',
          }}
        >
          <img
            src={selected.mainImage}
            alt=""
            style={{ maxWidth: '80%', paddingBottom: '3rem' }}
            className={`${animate ? 'image-animate' : 'image-none'} d-none`}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingTop: { xs: '2rem', md: '6rem' },
            marginBottom: { xs: '7rem', md: '0' },
          }}
        >
          <Box sx={{ marginLeft: { xs: '0.8rem', md: '0' } }}>
            <h2 className="font-size-45">Offered Services</h2>
            <Typography
              variant="body2"
              sx={{ width: '90%', textAlign: { xs: 'start', md: 'center' } }}
              className="grey-text-100 font-size-15"
            >
              Unlock the power of captivating design with our comprehensive
              services. Elevate your brand with stunning website, mobile app,
              banner, poster, and name card designs that leave a lasting
              impression. Let's bring your vision to life today!
            </Typography>
          </Box>
          <Box sx={{ marginLeft: { xs: '0.8rem', md: '0' } }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#29659A',
                borderRadius: '10px',
                marginTop: '2rem',
                padding: '0.5rem 0.4rem',
              }}
            >
              {' '}
              <Box></Box>
              <img
                src="/assets/Services/sms.png"
                alt=""
                style={{ marginRight: '0.3rem' }}
              />{' '}
              <h4 className="text-transform font-weight-500">Email us</h4>
            </Button>
          </Box>
          <Grid container sx={{ marginTop: '1rem' }}>
            <Grid item xs={11} md={6}>
              {views.slice(0, 2).map((value) => {
                return (
                  <Box
                    className={
                      value.id === selected.id
                        ? 'offer-services-box-hover'
                        : 'offer-services-box'
                    }
                    sx={{
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                    onMouseOver={() => handleHover(value)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={value.image} alt="" className="service-image" />
                    <Box sx={{ marginLeft: '1rem', marginTop: '10px' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
            <Grid item xs={11} md={6}>
              {views.slice(2, 4).map((value) => {
                return (
                  <Box
                    className={
                      value.id === selected.id
                        ? 'offer-services-box-hover'
                        : 'offer-services-box'
                    }
                    sx={{
                      margin: '1rem 0',
                      display: 'flex',
                      alignItems: 'start',
                      padding: '1rem',
                    }}
                    key={value.id}
                    onMouseOver={() => handleHover(value)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <img src={value.image} alt="" className="service-image" />
                    <Box sx={{ marginLeft: '1rem', marginTop: '10px' }}>
                      <span className="font-weight-600">{value.title}</span>
                      <p className="grey-text-100 font-size-15">
                        {value.description}
                      </p>
                    </Box>
                  </Box>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
