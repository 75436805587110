import React from 'react'
import { Box } from '@mui/material'

export default function index() {
  return (
    <Box className="hero-section">
      <Box sx={{ position: 'absolute', bottom: '0', left: '4%' }}>
        <h4 className="font-size-16 text-yellow ">
          Welcome to The Nard & Co. Holdings Pte. Ltd.
        </h4>
        <div style={{ position: 'relative', zIndex: 100 }}>
          <h6 className="font-larger font-weight-900" style={{ zIndex: 100 }}>
            Empowering
          </h6>

          <img
            src="/assets/Hero/Decore.svg"
            className="empowering-image"
            alt=""
          />
        </div>
        <h6 className="font-larger font-weight-900">Your Journey Together</h6>
        <h6 className="font-larger font-weight-900">With Us</h6>
        <p
          style={{ maxWidth: '520px', marginTop: '1rem' }}
          className="grey-text-100 font-size-15"
        >
          Welcome to our diverse business community where we prioritize building
          lasting relationships with our customers across industries, from the
          dynamic financial sector to the thriving realm of home-based business
          tech and even down to providing design services for your business
          needs! We firmly believe in the power of collaboration and growth,
          uniting with like minded individuals to pave the way for mutual
          success. Join us now on this journey of strength and prosperity as we
          advance together!
        </p>
      </Box>
    </Box>
  )
}
